import Vue from 'vue'
// import qs from "qs";
import axios from 'axios'
// import store from '@/store'
// import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN, DATA_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
    baseURL: '/api', //process.env.VUE_APP_API_BASE_URL, // api base_url
    //baseURL: 'http://39.100.227.12', // api base_url
    //baseURL: 'http://open.yuebu360.cn', // api base_url
    timeout: 20000, // 请求超时时间
})

const err = (error) => {
        // if (error.response) {
        //     const data = error.response.data
        //     const token = Vue.ls.get(ACCESS_TOKEN)
        //     if (error.response.status === 403) {
        //         // notification.error({
        //         //     message: 'Forbidden',
        //         //     description: data.message
        //         // })
        //     }
        //     if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
        //         // notification.error({
        //         //     message: 'Unauthorized',
        //         //     description: 'Authorization verification failed'
        //         // })

        //         // if (token) {
        //         //     store.dispatch('Logout').then(() => {
        //         //         setTimeout(() => {
        //         //             window.location.reload()
        //         //         }, 1500)
        //         //     })
        //         // }
        //     }
        // }
        return Promise.reject(error);
    }
    // request interceptor 拦截器
service.interceptors.request.use(config => {
    // const token = Vue.ls.get(ACCESS_TOKEN);
    // if (token) {
    //     config.headers['Access-Token'] = token;
    //     if (config.method === 'post') {
    //         if (config.data == null) {
    //             config.data = {};
    //         }
    //         config.data['token'] = token;
    //         // config.data['StoreCode'] = '083e23c734a7475595ca8f4b8636';
    //     }
    // }
    if(!config.data){config.data={}}
    config.data.UnCheckToken=true;
    return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
    return response.data
}, err)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, service)
    }
}

export {
    installer as VueAxios,
    service as axios
}