<template>
  <div>
    <!-- <input value="成功" type="button" class="PayOk" @click="PayCallBack(1,'')"/>
      <input value="失败" type="button" class="PayError"  @click="PayCallBack(-1,'超时未支付')"/> -->
    <!-- <br/>
      <h1>MsgStr:{{MsgStr}}</h1>
      <br/>
      <h1>PayStr:{{PayStr}}</h1>
      <br/>
      <h1>getBrandWCPayRequest:{{getBrandWCPayRequest}}</h1>
      <br/>
      PayOrderCode：{{PayOrderCode}}
      <br/>
      OpenIDOnServer：{{OpenIDOnServer}}
      <br/>
      WxPayObj{{WxPayObj}}
      <br/>-->
    <!-- {{testObj}}
      <br/> 
      PayStr1:{{PayStr1}}
      <br/> 
      PayStr2:{{PayStr2}}
      <br/> 
      PayStr3:{{PayStr3}}
      <br/> 
      PayStr4:{{PayStr4}} -->
  </div>
</template>
  
<script>
import moment from 'moment'
import { Toast, Indicator } from "mint-ui";
import { GetPayOrder, UpdatePayOrderState, PayPut } from "@/api/pay";
export default {
  data() {
    return {
      PayOrder: null,
      PayType: '',
      BillID: null,
      PayOrderCode: null,
      GoodsOrderCode: null,
      OpenIDOnServer: null,

      Loading: true,
      PaySuccess: false, // 支付成功
      PayError: false, // 支付失败
      Payed: false, // 已支付过的

      StopGetState: false, //停止侦听支付状态

      PayStr: "",
      PayStr1: "",
      PayStr2: "",
      PayStr3: "",
      PayStr4: "",

      testObj: null,
      WxPayObj: null,

      time: 0,
      ispay: 0,
      MsgStr: "",
      getBrandWCPayRequest: null,

      StartTime: null
    };
  },
  computed: {
    Stopwatch() {
      if (!this.StartTime) { this.StartTime = new Date() }
      var Now = new Date();
      return (Now.getTime() - this.StartTime.getTime()) / 1000;
    }
  },
  created() {
    Indicator.open("加载中");
    this.PayType = this.$route.query.PayType;
    this.PayOrderCode = this.$route.query.PayOrderCode;
    if (this.PayType == "TSRechargeAndCheck") {
      this.BillID = this.$route.query.BillID;
    }
    else if (this.PayType == "StoredRecharge") {
      this.StoredRechargeCode = this.$route.query.StoredRechargeCode;
    }
    else if (this.PayType == "TSCheck") {//TSCheck
      this.BillID = this.$route.query.BillID;
    }
    else if (this.PayType == "GoodsOrder") {//TSCheck
      this.GoodsOrderCode = this.$route.query.GoodsOrderCode;
    }

    // 需进行已支付验证
    this.testObj = res.data;
    this.WxPayObj = res.data;

    this.time = setInterval(() => {
      //超时处理
      //WeixinJSBridge加载需要1-2秒  WeixinJSBridge is not defined
      if (typeof WeixinJSBridge != "undefined" && this.ispay == 0) {
        this.ispay = 1;
        this.onBridgeReady(this.WxPayObj);
        clearInterval(this.time);
      }
    }, 500);

    if (typeof WeixinJSBridge == "undefined") {
      if (document.addEventListener) {
        document.addEventListener(
          "WeixinJSBridgeReady",
          onBridgeReady,
          false
        );
      } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
        document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
      }
    } else {
      this.onBridgeReady(this.WxPayObj);
    }
  },
  mounted() {
    this.GetPayState(); // 开始侦听支付结果
  },
  beforeDestroy() {
    // 销毁前停止侦听
    console.log("销毁前停止侦听");
    Indicator.close();
    this.StopGetState = true;
  },
  methods: {
    PayCallBack(state, errorMsg) {
      // 调用接口确认支付状态
      var params = {
        PayOrderCode: this.PayOrderCode,
        PayState: state,
        ErrorMsg: errorMsg
      };
      UpdatePayOrderState(params)
        .then(res => {
          if (res.state == 200) {
            console.log("支付状态修改成功", res);
          } else {
            console.error("支付状态修改异常", res);
          }
        })
        .catch(res => {
          console.warn("UpdatePayOrderState.catch", res);
        });
    },
    // 感知支付状态
    GetPayState() {
      setTimeout(() => {
        if (this.StopGetState) {
          return;
        }
        GetPayOrder({ PayOrderCode: this.PayOrderCode })
          .then(res => {
            if (res.state == 200) {
              const { PayState, ErrorMsg } = res.data;
              if (PayState == 1) {
                this.PaySuccess = true;
                console.log("已感知到-支付成功", res);
                this.PayStr = "已感知到-支付成功" + new Date();
                this.StopGetState = true;
                this.PayOkContinue();
              }
              else if (PayState == -1) {
                this.PayError = true;
                console.warn("已感知到-支付失败", res);
                this.PayStr = "已感知到-支付失败" + new Date();
                this.StopGetState = true;
                // this.$router.go(-1);
                Toast({
                  message: '已感知到-支付失败01！😀',
                  position: 'top',
                  duration: 2000
                });
                return;
              }
              else {
                this.PayStr = "支付结果未变化" + new Date();
                this.GetPayState();
              }
            }
            else {
              console.error("感知支付状态异常", res);
            }
          })
          .catch(res => {
            console.warn("GetPayOrder.catch", res);
          });
      }, 3000);
    },
    // 支付成功后继续执行
    PayOkContinue() {
      this.$store.commit("RefreshMember");
      if (this.PayType == "TSRechargeAndCheck") {
        this.MsgStr = "跳转到评价页";
        this.$router.push({ name: "comment", query: { BillID: this.BillID } });
      }
      else if (this.PayType == "TSCheck") {//TSCheck
        this.MsgStr = "跳转到评价页";
        this.$router.push({ name: "comment", query: { BillID: this.BillID } });
      }
      else if (this.PayType == "StoredRecharge") {
        this.MsgStr = "跳转到储值记录";
        this.$router.push({ name: "StoredDetail" });
      }
      else if (this.PayType == "GoodsOrder") {//TSCheck
        this.MsgStr = "跳转到卡券列表";
        this.$router.push({ name: "CardList", query: { CanotBack: true } })
      }
    },
    onBridgeReady(PayObj) {
      Indicator.close();
      var that = this;
      this.PayStr = "onBridgeReady";
      this.PayStr1 = new Date();
      this.getBrandWCPayRequest = {
        appId: PayObj.appId, //公众号名称，由商户传入
        timeStamp: PayObj.timeStamp, //时间戳，自1970年以来的秒数
        nonceStr: PayObj.nonceStr, //随机串
        package: PayObj.packages,
        signType: PayObj.signType, //微信签名方式：
        paySign: PayObj.paySign //微信签名
      };

      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: PayObj.appId, //公众号名称，由商户传入
          timeStamp: PayObj.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: PayObj.nonceStr, //随机串
          package: PayObj.packages,
          signType: PayObj.signType, //微信签名方式：
          paySign: PayObj.paySign //微信签名
        },
        function (res) {
          that.PayStr2 = new Date();
          let dura = t2.format('x') - t1.format('x');

          let tempTime = this.moment.duration(dura);

          that.PayStr3 = tempTime.seconds()
          that.PayStr4 = tempTime.milliseconds()

          that.testObj = res;
          that.PayStr = "onBridgeReady.function";
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            that.PayStr = "支付成功";
          } 
          else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            that.PayStr = "支付过程中用户取消";
            // that.$router.go(-1);
            Toast({
              message: '支付过程中用户取消02！😀',
              position: 'top',
              duration: 10000
            });
          } 
          else if (res.err_msg == "get_brand_wcpay_request:fail") {
            that.PayStr = "支付失败";
            // that.$router.go(-1);
            Toast({
              message: '支付失败03！😀',
              position: 'top',
              duration: 2000
            });
          }
          else if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {
            that.PayStr =              "1、请检查预支付会话标识prepay_id是否已失效<br/>>2、请求的appid与下单接口的appid是否一致";
          }

          // Indicator.close();

        }
      );
    }
  },
  watch: {
    PayOrder(newValue, oldValue) {
      if (newValue) {
        if (newValue.PayState == 0) {
          this.PayOrderCode = newValue.PayOrderCode;
          console.log("开始支付", newValue);
        } else {
          this.Payed = true;
          this.StopGetState = true;
          console.warn("支付单已支付，请勿重复支付", newValue);
        }
      }
    }
  }
};
</script>
<style scoped>
input[type="button"] {
  width: 200px;
  height: 50px;
  margin: 20px auto;
}
</style>
  