<template>
	<div class="pay-result pay-warn">
		<div class="pay-result-cot">
			<div class="img">
				<img src="../../../../static/images/pay-result-3.png" alt="支付状态" />
			</div>
			<h6 class="title">请重新扫描二维码或咨询服务员！</h6>
			<p class="text">{{errorText}}</p>
      <p class="miniinfo">{{this.$route.params}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'invalid',
		data() {
			return {
				errorText:'404', // 错误提示
				fromPath:'', // 错误提示
			}
		},
		created() {
			if(this.$route.query.Msg){
                this.errorText = this.$route.query.Msg;
			}
			if(this.$route.params.Msg){
                this.errorText = this.$route.params.Msg;
			}
			if(this.$route.params.From){
                this.fromPath = this.$route.params.From;
			}
            console.log(this.$route,this.$route.query,this.$route.params);
		},
		methods:{
			
		},
		beforeDestroy(){
	    }
	}
</script>

<style lang="scss" scoped>
	$main: #ffbb05;
	$red: #ff423e;
	$border: #e6e6ea;
	$grey: #777;
	@mixin border-radius($size) {
		-webkit-border-radius: $size;
		-moz-border-radius: $size;
		border-radius: $size;
	}
	@mixin transition($time){
		-webkit-transition: all $time;
		-moz-transition: all $time;
		-ms-transition: all $time;
		-o-transition: all $time;
		transition: all $time;
	}
	/* pay-result */
	.pay-result {
		.pay-result-cot {
			width: 100%;
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -150px;
			padding: 0 20px;
			.img {
				width: 160px;
				margin: 0 auto;
				img {
					width: 100;
				}
			}
			.title {
				font-size: 16px;
				line-height: 1;
				text-align: center;
				margin-top: 25px;
			}
			.text {
				font-size: 12px;
				color: $grey;
				text-align: center;
				margin-top: 10px;
				.num {
					color: $red;
				}
			}
		}
		.btn {
			height: 50px;
			line-height: 50px;
			text-align: center;
			position: absolute;
			bottom: 10px;
			left: 10px;
			right: 10px;
			background: $main;
			@include border-radius(25px);
		}
	}
	/* pay-result end */

  .miniinfo{font-size: 9px;color: #999;}
</style>