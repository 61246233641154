<template>
<div class="container">
    <div>StoredRecordCode:{{StoredRecordCode}}</div>
    <div>TradePrincipal:{{TradePrincipal}}</div>
    <div>TradeGift:{{TradeGift}}</div>
    <div>StoredGradientCode:{{StoredGradientCode}}</div>
    <div @click="StoredRechargeOk">成功</div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { RechargeOK } from '@/api/stored'
import { GetBillCard } from '@/api/card'
export default {
    name: 'excharge',
    data() {
        return {
            StoredRecordCode:'',
            TradePrincipal:0,
            TradeGift:0,
            StoredGradientCode:'',
        }
    },
    computed: {
        ...mapGetters(['MemberCode', 'StoreCode', 'DeskID', 'Member' ]),
    },
    created() {
        this.StoredRecordCode=this.$route.query.StoredRecordCode;
        this.TradePrincipal=this.$route.query.TradePrincipal;
        this.TradeGift=this.$route.query.TradeGift;
        this.StoredGradientCode=this.$route.query.StoredGradientCode;

        
    },
    methods: {
        ...mapActions([ 'PushErrorPage']),
        // 开始执行充值
        StoredRechargeOk(){
            var paramer={};
            paramer.StoredRecordCode=this.StoredRecordCode; 
            paramer.TradePrincipal=this.TradePrincipal; 
            paramer.TradeGift=this.TradeGift; 
            paramer.StoredGradientCode=this.StoredGradientCode;
            RechargeOK(paramer).then(res=>{
                if(res.state==200){
                    this.$router.push({ name: 'Recharge'});
                }
                else{
                    console.log('RechargeOK.res', res)
                }
            }).catch(res=>{console.error('RechargeOK.catch', res)});
        },

    }
}
</script>

<style lang="scss" scoped>
</style>
