import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store/'
// import Axios from 'axios';
// Vue.prototype.$axios = Axios;

import MintUI from 'mint-ui';
import 'mint-ui/lib/style.css'; 
Vue.use(MintUI); 
// import { Toast,  Indicator} from "mint-ui"; 
// Vue.prototype.$toast=Toast;
// Vue.prototype.$indicator=Indicator;
import Storage from 'vue-ls'
// vue-ls 的配置
const storageOptions = {
    namespace: 'vue_',   // key 键的前缀（随便起）
  	name: 'ls',          // 变量名称（随便起） 使用方式：Vue.变量名称 或 this.$变量名称
  	storage: 'local'     // 作用范围：local、session、memory
}

Vue.use(Storage, storageOptions)


//css start--global
import '../static/css/reset.css';
import '../static/css/global.css';
import '../static/css/font_1336499_40j1tps7e2h.css';
//css begin--global end


import '../static/iconfont2/iconfont.css';
import '../static/iconfont/iconfont.css';

Vue.config.productionTip = false
import versionTood from '@/utils/versionUpdate';
// 路由预先判断
router.beforeEach(async(to, from, next) => {
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion();
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
})


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
