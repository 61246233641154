import { axios } from '@/utils/request'

//消费记录
export function GetBillList(parameter) {
    return axios({
        url: '/Member/Bill/GetList',
        method: 'post',
        data: parameter
    });
}
//消费详情
export function GetBillDetail(parameter) {
    return axios({
        url: '/Operation/BillInfo',
        method: 'post',
        data: parameter
    });
}
//加载账单评价规则
export function GetCommentRules(parameter) {
    return axios({
        url: '/Comment/BillComment/Get',
        method: 'post',
        data: parameter
    });
}
//保存账单评价
export function SubmitComment(parameter) {
    return axios({
        url: '/Comment/BillComment/Create',
        method: 'post',
        data: parameter
    });
}
//加载账单评价记录
export function GetBillComment(parameter) {
    return axios({
        url: '/Comment/BillComment/GetBillComment',
        method: 'post',
        data: parameter
    });
}
//获取买单确认状态
export function GetCheckOrderConfirmState(parameter) { return axios({ url: '/Member/Bill/GetCheckOrderConfirmState', method: 'post', data: parameter }); }
//设置买单确认状态
export function SetCheckOrderConfirmState(parameter) { return axios({ url: '/Member/Bill/SetCheckOrderConfirmState', method: 'post', data: parameter }); }
