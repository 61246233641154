import { axios } from '@/utils/request'


//获取卡券营销活动列表
export function GetMarketingOnCard(parameter) {
    return axios({
      url: '/Marketing/MarketingOnCard/Get',
      method: 'post',
      data: parameter
    });
  }


  //获取卡券营销活动列表
export function CheckLimit(parameter) {
  return axios({
    url: '/Marketing/MarketingOnCard/CheckLimit',
    method: 'post',
    data: parameter
  });
}


  //获取卡券营销活动列表
  export function CreateRecord(parameter) {
    return axios({
      url: '/Marketing/MarketingOnCard/CreateRecord',
      method: 'post',
      data: parameter
    });
  }

  export function GetMarketingOnStored(parameter) {
    return axios({
        url: '/Marketing/MarketingOnStored/Get',
        method: 'post',
        data: parameter
    });
}