import { axios } from '@/utils/request'


export function GetMember(parameter) {
    return axios({
        url: '/Member/Get',
        method: 'post',
        data: parameter
    });
}
//会员等级列表
export function GetMemberRankList(parameter) {
    return axios({
        url: '/Member/MemberRank/GetList',
        method: 'post',
        data: parameter
    });
}
//会员等级详情
export function GetMemberRankDetail(parameter) {
    return axios({
        url: '/Member/MemberRank/Get',
        method: 'post',
        data: parameter
    });
}

//会员等级特权列表
export function GetPrivilegeList(parameter) {
    return axios({
        url: '/Member/MemberRankPrivilege/GetList',
        method: 'post',
        data: parameter
    });
}
//会员等级特权详情
export function GetPrivilegeDetail(parameter) {
    return axios({
        url: '/Member/MemberRankPrivilege/Get',
        method: 'post',
        data: parameter
    });
}
//会员储值信息
export function GetStoredInfo(parameter) {
    return axios({
        url: '/Stored/Get',
        method: 'post',
        data: parameter
    });
}
//会员积分记录
export function GetIntegralRecord(parameter) {
    return axios({
        url: '/Integral/Record',
        method: 'post',
        data: parameter
    });
}  
export function GetEmployee(parameter) { return axios({ url: '/Member/Fans/GetEmployee', method: 'post', data: parameter }); }
export function UpdatePhone(parameter) { return axios({ url: '/Member/Update/Phone', method: 'post', data: parameter }); }
export function GetEmployeeCode(parameter) { return axios({ url: '/Org/Employee/GetEmployeeCode', method: 'post', data: parameter }); }