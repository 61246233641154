<template>
	<div class="container" ref="wrapper" :style="{ height: wrapperHeight + 'px' }">
		<!-- banner -->

		<div v-if="bannerList.length != 0" class="banner" style="border-radius: 5px;">
			<mt-swipe :auto="4000">
				<mt-swipe-item v-for="item in bannerList" :key="item">
					<div :style="'background-image:url(' + item + ');'" class=" bannerimgitem"></div>
				</mt-swipe-item>
			</mt-swipe>
		</div>
		<!-- banner end -->
		<!-- location -->
		<div class="location" v-if="false">
			<div class="location-address">
				<div class="icon">
					<img src="../../../static/images/location.png" alt="定位标" />
				</div>
				<p class="text">{{ location }} - <span style="font-weight: bold;">附近门店</span></p>
				<!-- <p class="text">{{location}}</p> -->
			</div>
			<div class="location-btn" @click="repeatLocal">
				<i class="iconfont icondingwei"></i>
				<p class="text" @click="geolocation.getLocation(showPosition, showErr, options)">重新定位</p>
			</div>
		</div>
		<!-- location end -->
		<!-- nearby -->
		<div class="nearby" v-if="false">
			<!-- <div class="global-title" style="border-radius: 5px;line-height: 30px;background: #fff;padding: 12px 10px;" >
					<h4 class="title" style="height: 16px;font-size:16px;text-indent: 10px;">附近门店</h4>
				</div> -->
			<div class="nearby-list" style=" margin-top: 15px;">
				<ul class="list">
					<li v-for="(store, index) in NearbyStores" :key="store.StoreCode + index">
						<div class="item">
							<router-link :to="{ name: 'dishMenu2023', query: { StoreCode: store.StoreCode } }">
								<div class="img" v-if="store.ImageUrl"
									:style="'background-image: url(' + store.ImageUrl + ')'">
								</div>
								<div class="img" v-else>
									<div class="error">{{ store.StoreName }}</div>
								</div>
								<div class="text-box">
									<h6 class="title">{{ store.StoreName }}</h6>
									<div class="data-box">
										<p class="distance" style="width: 100%;" v-if="store.JuLi.distance >= 1000">
											距离{{ parseInt(store.JuLi.distance / 1000 * 10) / 10 }}km</p>
										<p class="distance" style="width: 100%;" v-else>距离{{ store.JuLi.distance }}m</p>
										<p class="address">地址：{{ store.Address ? store.Address : "未录入地址信息" }}</p>
									</div>
								</div>
							</router-link>
							<div class="btn-box" v-if="store.Latitude && store.Longitude">
								<a :href="'https://apis.map.qq.com/uri/v1/geocoder?coord=' + store.Latitude + ',' + store.Longitude + ';referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77'"
									class="navigation">
									<i class="iconfont iconjuli"></i>
									<p class="text">导航</p>
								</a>
								<a :href="'tel:' + store.Tell" class="tel" v-if="store.Tell">
									<i class="iconfont icondianhua1"></i>
									<p class="text">电话</p>
								</a>
								<router-link :to="{ name: 'dishMenu2023', query: { StoreCode: store.StoreCode } }"
									class="tel">
									<i class="iconfont iconzhongjiefangx"></i>
									<p class="text">菜单</p>
								</router-link>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<!-- nearby end -->

		<div v-if="Areas.length > 1" class="nearby" style="margin-top: 12px;">
			<div class="location" style="padding: initial;">
				<div class="location-address">
					<i class="iconfont iconmeiguoyiliao" style="color:#ffbb05"></i><span class="text"
						style="float: initial;">全部门店</span>
				</div>
			</div>
		</div>
		<!-- city -->
		<div class="city">
			<div class="city-tab" v-if="Areas.length > 1">
				<ul class="tab-list clearfix">
					<li v-for="area in Areas" :key="area.AreaCode"
						:class="ActiveArea && area.AreaName == ActiveArea.AreaName ? 'active' : ''"
						@click="ActiveArea = area">
						{{ area.AreaName }} ({{ area.Stores.length }}家)</li>
				</ul>
			</div>
			<div class="city-list-box" v-if="ActiveArea" style="margin-top: 10px;">
				<ul class="city-list">
					<li v-for="store in ActiveArea.Stores" :key="store.StoreCode"
						v-show="store.IsDel == 0 || store.State == 1">
						<div class="item">
							<router-link :to="{ name: 'dishMenu2023', query: { StoreCode: store.StoreCode } }">
								<div class="img" v-if="store.ImageUrl"
									:style="'background-image: url(\'' + store.ImageUrl + '\')'"></div>

								<div class="img" v-else>
									<div class="error">{{ store.StoreName }}</div>
								</div>
								<div class="text-box">
									<h6 class="title">{{ store.StoreName }}</h6>
									<div class="data-box">
										<p class="address">地址：{{ store.Address ? store.Address : "未录入地址信息" }}</p>
										<!-- <p class="distance">距离{{item.distance}}米</p> -->
										<!-- <p class="pay"></p> -->
									</div>
									<!-- <div class="data-box">
											<p class="distance">距离{{item.distance}}米</p>
											<p class="pay">人均消费￥{{item.pay}}</p>
										</div> -->
								</div>
							</router-link>
							<div class="btn-box">
								<a :href="'https://apis.map.qq.com/uri/v1/geocoder?coord=' + store.Latitude + ',' + store.Longitude + ';referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77'"
									class="navigation">
									<i class="iconfont iconjuli"></i>
									<p class="text">导航</p>
								</a>
								<a :href="'tel:' + store.Tell" class="tel">
									<i class="iconfont icondianhua1"></i>
									<p class="text">电话</p>
								</a>
								<!-- <router-link :to="{name:'dishMenu2023',query:{StoreCode:store.StoreCode}}" class="tel"> 
										<i class="iconfont iconzhongjiefangx"></i>
										<p class="text">菜单</p>
									</router-link> -->
								<div @click="JumpDishMenu(store)" class="tel">
									<i class="iconfont iconzhongjiefangx"></i>
									<p class="text">菜单</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="city" v-if="false">
			<div class="city-tab">
				<ul class="tab-list clearfix">
					<li v-for="item in citytablist" :key="item.id" :data-index="item.id"
						:class="item.active ? 'active' : ''" @click="changeArea">{{ item.text }}</li>
				</ul>
			</div>
			<div class="city-list-box">
				<ul class="city-list">
					<li v-for="item in citystorelist" :key="item.id">
						<div class="item">
							<router-link :to="{ name: 'store', params: { id: item.id } }">
								<div class="img">
									<img :src="item.src" :alt="item.alt" />
								</div>
								<div class="text-box">
									<h6 class="title">{{ item.name }}</h6>
									<div class="data-box">
										<p class="distance">距离{{ item.distance }}米</p>
										<p class="pay">人均消费￥{{ item.pay }}</p>
									</div>
								</div>
							</router-link>
							<div class="btn-box" v-if="item.longitude && item.latitude">
								<a :href="'https://apis.map.qq.com/uri/v1/geocoder?coord=' + item.longitude + ',' + item.latitude + ';referer=W6FBZ-JJ6RF-PF3JW-JE6LP-Q563K-MLFGZ'"
									class="navigation">
									<i class="iconfont iconjuli"></i>
									<p class="text">导航</p>
								</a>
								<a :href="'tel:' + item.tel" class="tel" v-if="item.tel">
									<i class="iconfont icondianhua1"></i>
									<p class="text">电话</p>
								</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<!-- city end -->

		<!-- <i class="iconfont iconmeiguoyiliao"></i>
										<i class="iconfont iconerweima1"></i>
										<i class="iconfont icondingwei"></i>
										<i class="iconfont icontuikuan"></i>
										<i class="iconfont iconhuo"></i>
										<i class="iconfont iconjiajianzujianjiahao"></i>
										<i class="iconfont iconsousuo1"></i>
										<i class="iconfont iconVIP"></i>
										<i class="iconfont iconzhongjiefangx"></i> -->
		<!-- <div class="support">本服务由越步科技提供技术支持</div> -->
	</div>
</template>

<script>
import Vue from "vue";
import { GetStoreList, GetAreaList } from '@/api/store'
import { mapActions, mapGetters } from 'vuex'
import { Toast, Indicator } from 'mint-ui';

export default {
	name: 'Home',
	data() {
		return {
			location: '定位中...', // 定位信息
			bannerList: [], // banner
			storelist: [], // 附近门店
			citytablist: [], // 城市列表
			citystorelist: [], // 城市门店列表
			allLoaded: false, // 是否全部加载
			isAutoFill: false, // 自动上拉加载一次
			wrapperHeight: 0, // 页面高度

			NearbyStores: [],
			Stores: [],

			// Areas:[], 
			Areas: [{ AreaName: '武汉', Stores: [] }, { AreaName: '宜昌', Stores: [] }, { AreaName: '荆州', Stores: [] }, { AreaName: '荆门', Stores: [] }, { AreaName: '其他', Stores: [] }],
			ActiveArea: null,

			// geolocation:null,
			options: { timeout: 8000 }
		}
	},
	computed: {
		...mapGetters(['Member', 'StoreCode', 'DeskID', "BusinessConfig", 'MediaService', 'Business']),
	},
	mounted() {

		console.log('Business', this.Business)
		document.title = this.Business.BusinessName + '-选择门店'
		// this.geolocation = new qq.maps.Geolocation("W6FBZ-JJ6RF-PF3JW-JE6LP-Q563K-MLFGZ", "越步科技");
		Indicator.open('加载中....');
		GetStoreList({ BusinessCode: this.Member.BusinessCode, pageSize: 9999, pageNo: 1 }).then(res => {
			if (res.state != 200) { this.PushErrorPage(res); return }
			// const arr=[]
			res.data = res.data.filter(o => o.State == 1)
			const result = res.data.reduce((a, b) => {
				if (a[b.AreaCode]) {
					a[b.AreaName].push(b);
				} else {
					a[b.AreaName] = [b]
				}
				return a
			}, []);


			const arr = [];
			res.data.forEach(item => {
				const parent = arr.find(cur => cur.AreaCode === item.AreaCode)
				if (parent) {
					parent.Stores.push(item)
					parent.AreaName = item.AreaName
					parent.AreaCode = item.AreaCode
				} else {
					const obj = { AreaName: item.AreaName, AreaCode: item.AreaCode, Stores: [item] }
					arr.push(obj)
				}
			})


			this.Stores = res.data;
			this.Areas = arr;
			// this.Stores.forEach(store => {
			// 		//宜昌区
			//     if(store.StoreCode=='6eaf1c5c724e444d83307f46afe6851b'||store.StoreCode=='fd34939f9ea3497fad053223d5981f3e'){
			//       this.Areas[4].Stores.push(store);
			//     }
			//     else if(store.AreaCode=='3b21a0cb6aee4ff395fafe9bfbdc8e72'){
			//       this.Areas[3].Stores.push(store);
			//     }
			// 		else if(store.AreaCode=='83f0ffd1fde84b3db96383d68b72f93d'){
			// 			this.Areas[1].Stores.push(store);
			// 		//荆州区
			// 		}else if(store.AreaCode=='c5e6baa9861e452dbd420bc16721e474'){
			//       this.Areas[2].Stores.push(store);
			// 		//武汉区
			// 		}else{
			// 			this.Areas[0].Stores.push(store);
			// 		}
			// 	});
			if (this.Areas.length > 0) {
				this.ActiveArea = this.Areas[0];
			}
			// this.$nextTick(()=>{
			//   this.geolocation.getLocation(this.showPosition, this.showErr, this.options);
			// })
			// GetAreaList({BusinessCode:this.Member.BusinessCode}).then(res=>{
			//   if(res.state!=200){
			//     console.error('GetAreaList.then',res);
			//     Toast({message: "网络不太顺畅，请稍后刷新再试！",position: "top",duration: 2000});
			//     return;
			//   }
			//   this.Areas=[];
			//   res.data.forEach(area => {
			//     area.Stores=[];
			//     this.Stores.forEach(store => {
			//       if(store.AreaCode==area.AreaCode){
			//         area.Stores.push(store);
			//       }
			//     });
			//     // console.log('this.Stores.filter(a=>{a.AreaCode==area.AreaCode})',this.Stores.filter(a=>{a.AreaCode===area.AreaCode}));
			//     // area.Stores=area.Stores.concat(this.Stores.filter(a=>{a.AreaCode==area.AreaCode}));
			//     this.Areas.push(area);
			//   });
			//   if(this.Areas.length>0){
			//     this.ActiveArea=this.Areas[0];
			//   }
			//   console.log('this.Areas',this.Areas);
			//   this.$nextTick(()=>{
			//     this.geolocation.getLocation(this.showPosition, this.showErr, this.options);
			//   })
			// }).catch(err=>{
			//   console.error('GetAreaList.catch',err);
			//   Toast({message: "网络不太顺畅，请稍后刷新再试！",position: "top",duration: 2000});
			//   return;
			// });
		}).catch(res => {
			console.error('GetStoreList.catch', res);
			Toast({ message: "网络不太顺畅，请稍后刷新再试！", position: "top", duration: 2000 });
			return;

		}).finally(res => { setTimeout(() => { Indicator.close(); }) });




		this.$axios.get('../static/js/data.json')
			.then((res) => {
				this.storelist = res.data.getNearbyList;
				this.citytablist = res.data.getCity;
				this.citystorelist = res.data.getCityList;
				if (this.BusinessConfig.AdsenseConfig && this.BusinessConfig.AdsenseConfig.StoresHeadBanners.length > 0) {
					console.log('this.BusinessConfig', this.BusinessConfig)
					this.BusinessConfig.AdsenseConfig.StoresHeadBanners.forEach(pic => {
						this.bannerList.push(pic)
					})
				} else {
					this.bannerList = [this.MediaService + '/FileRoot/AdsensePic/2021/4/4fcb3995-cb66-448b-88eb-f29daad34c89.jpg'];
				}
			})
			.catch(err => console.log)

		// 父控件要加上高度，否则会出现上拉不动的情况
		this.wrapperHeight = document.documentElement.clientHeight - this.$refs.wrapper.getBoundingClientRect().top;

	},
	methods: {
		...mapActions(['InitMember', 'NeedMember', 'NeedBusinessConfig']),
		JumpDishMenu(store) {
			if (this.$store.getters.Store && this.$store.getters.Store.StoreCode != store.StoreCode) {
				// if(this.$route.query.StoreCode && this.$route.query.StoreCode!=this.StoreCode){
				// this.StoreCode=this.$route.query.StoreCode;
				Vue.ls.set(this.Member.MemberCode+'_StoreCode', this.StoreCode);
				Vue.ls.remove(this.Member.MemberCode+'_DeskID'); 
				this.$store.commit('set_Desk', null);
			}
			this.$router.push({ name: 'dishMenu2023', query: { StoreCode: store.StoreCode } })
		},
		// 重新定位
		repeatLocal() {
			try {
				// this.geolocation.getLocation(this.showPosition, this.showErr, this.options);
			}
			catch (err) {
				Toast({ message: "repeatLocal.catch" + JSON.stringify(err), position: "top", duration: 10000 })
			}
			// this.location = '武汉';
		},
		showPosition(position) {
			try {
				console.log('showPosition', position);
				Toast({ message: "showPosition:" + JSON.stringify(position), position: "top", duration: 10000 })
				this.location = position.city;
				var fromLocation = position.lat + ',' + position.lng;
				var toLocation = '';
				var LocationIndex = 0;
				this.Stores.forEach(store => {
					if (store.Latitude && store.Longitude) {
						store.LocationIndex = LocationIndex;
						LocationIndex++;
						if (toLocation.length > 0) { toLocation = toLocation + ';'; }
						toLocation = toLocation + store.Latitude + ',' + store.Longitude;
					}
				});
				console.log('toLocation', toLocation);
				var that = this;
				this.$axios.get('/mapqq/ws/distance/v1/matrix/?mode=driving&from=' + fromLocation + '&to=' + toLocation + '&key=W6FBZ-JJ6RF-PF3JW-JE6LP-Q563K-MLFGZ')
					.then(function (response) {
						console.log(response);
						// Toast({message:"获得定位信息then："+JSON.stringify(response),position:"top",duration:10000})
						var JuLiData = response.data.result.rows[0].elements;
						that.NearbyStores = [];
						var Nearby1Store = null;
						var Nearby2Store = null;
						JuLiData.forEach((juli, index) => {
							var Store = that.Stores.find(a => a.LocationIndex == index);
							if (Store) {
								Store.JuLi = juli;
								if (!Nearby1Store || juli.distance < Nearby1Store.JuLi.distance) {
									Nearby2Store = Nearby1Store;
									Nearby1Store = Store;
								}
								else if (!Nearby2Store || juli.distance < Nearby2Store.JuLi.distance) {
									Nearby2Store = Store;
								}
							}
						});
						console.log(Nearby1Store, Nearby2Store);
						that.NearbyStores.push(Nearby1Store)
						that.NearbyStores.push(Nearby2Store)
					})
					.catch(function (error) {
						Toast({ message: "获得定位信息catch：" + JSON.stringify(response), position: "top", duration: 10000 })
						console.log(error);
					});
			}
			catch (err) {
				Toast({ message: "showPosition.catch" + JSON.stringify(err), position: "top", duration: 10000 })
			}
		},
		showErr() {
			console.log('showErr', '定位失败！');
			Toast({ message: "定位失败！", position: "top", duration: 10000 })
		},


		// 触底加载
		loadBottom() {
			this.$axios.get('getCityList')
				.then((res) => {
					if (res) {
						this.citystorelist = this.citystorelist.concat(res.data);
						this.$refs.loadmore.onBottomLoaded(); // 重置下拉加载
					} else {

						this.allLoaded = true; // 数据已全部获取完毕
					}

				})
				.catch(err => console.log(err));
		},
		PushErrorPage(res) {
			this.$router.push({ name: 'invalid', query: { Msg: res.state + '-' + res.msg, From: window.location.href } });
		},
	}
}
</script>
<style >
.bannerimgitem {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: 10px;
}

.global-title:after {
	content: '';
	width: 3px;
	height: 14px;
	background: #ffbb05;
	position: absolute;
	left: 10px;
	top: 50%;
	border-radius: 2px;
	margin-top: -7px;
}
</style>
<style lang="scss" scoped>
$main: #ffbb05;
$red: #ff423e;
$border: #e6e6ea;
$grey: #777;

@mixin border-radius($size) {
	-webkit-border-radius: $size;
	-moz-border-radius: $size;
	border-radius: $size;
}

.container {
	overflow: scroll;
}

.mint-loadmore-content {}

.mint-loadmore-bottom {}

/* location */

.location {
	padding: 15px 10px;

	.location-address {
		float: left;

		.icon {
			height: 16px;
			float: left;

			img {
				height: 100%;
				display: block;
			}
		}

		.text {
			font-size: 14px;
			line-height: 16px;
			float: left;
			margin-left: 5px;
			font-weight: bold;
		}
	}

	.location-btn {
		height: 16px;
		line-height: 16px;
		float: right;

		i {
			font-size: 14px;
			color: $main;
			float: left;
			display: block;
		}

		.text {
			font-size: 14px;
			float: left;
			color: $main;
			margin-left: 5px;
		}
	}
}

/* location end */
/* banner */

.banner {
	height: 40vw;
	margin: 10px;

	.mint-swipe {
		width: 100%;
		height: 100%;
	}

	.img {
		width: 100%;
		height: 40vw;
		overflow: hidden;
		display: block;
		@include border-radius(5px);

		img {
			width: 100%;
			display: block;
		}
	}
}

/* banner end */
/* nearby */

.nearby,
.city-list {
	padding: 0 10px;

	// margin-top: 15px;
	li {
		// padding: 0 0 10px;
		margin-bottom: 15px;
		border-radius: 5px;

		// border-bottom: 1px solid $border;
		&:last-child {
			border-bottom: none;
		}
	}

	.item {
		height: 120px;
		position: relative;
		border-radius: 5px;
		background-color: #fff;
		padding: 10px;
		-webkit-box-shadow: 1px 2px 5px #afafaf;
		box-shadow: 1px 2px 5px #afafaf;
	}

	.img {
		width: 100px;
		height: 100px;
		@include border-radius(5px);
		overflow: hidden;
		float: left;
		display: block;
		background: no-repeat center;
		background-size: cover;

		img {
			width: 100%;
			display: block;
			font-size: 12px;
			width: 100px;
			height: 100px;
			line-height: 100px;
			text-align: center;
		}

		.error {
			display: block;
			font-size: 12px;
			color: #fff;
			text-align: center;
			background-color: #000;
			width: 100px;
			height: 100px;
			line-height: 100px;
		}
	}

	.text-box {
		margin-left: 110px;
		height: 100%;

		.title {
			font-size: 18px;
			font-weight: bold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.data-box {
			margin-top: 5px;

			.address {
				color: $grey;
				line-height: 18px;
				font-size: 14px;
				float: left;
			}

			.distance {
				color: $red;
				line-height: 1;
				font-size: 14px;
				float: left;
			}

			.pay {
				color: $grey;
				line-height: 1;
				font-size: 12px;
				float: left;
				margin-left: 20px;
			}
		}
	}

	.btn-box {
		position: absolute;
		bottom: 10px;
		right: 10px;
		// left: 150px;
		width: 100%;

		a:first-child {
			margin-right: 0;
		}

		.navigation,
		.tel {
			width: 60px;
			height: 26px;
			line-height: 26px;
			text-align: center;
			font-size: 10px;
			float: right;
			color: #333;
			background: $main;
			margin-right: 10px;
			display: block;
			@include border-radius(12px);

			i {
				font-size: 12px;
				display: inline-block;
			}

			.text {
				font-size: 12px;
				display: inline-block;
			}
		}
	}
}

/* nearby end */
/* city */

.city {
	padding: 0 0 5px;
	clear: both;

	.city-list {
		// margin-top: 5px;
		// overflow: hidden; 
	}

	.city-tab::-webkit-scrollbar {
		width: 0;
	}

	.city-tab {
		padding: 0 10px;
		white-space: nowrap;
		overflow-y: hidden;
		;

		// height: 31px;
		li {
			text-align: center;
			font-size: 14px;
			display: inline-block;
			border: 3px solid #ffbb05;


			line-height: 25px;
			margin: 0 6px 0px 0;
			// float: left;
			padding: 0 7px;
			// height: 25px;
			// display: block;
			// background: #f2f2f6;
			background: #fff;
			@include border-radius(5px);
			font-size: 12px;
		}

		li:last-child {
			margin-right: 10px;
		}

		.active {
			background: $main;
			// color: #fff;
		}
	}

	/*.city-list-box {
			height: 332px;
			margin-top: 5px;
			overflow: scroll;
		}*/
}

/* city end */</style>