<template>
  <div>
    {{PayService}}
    <br>
    <json-viewer :value="PayOrder" :expand-depth='5' copyable  > </json-viewer>
    <br>
    <json-viewer :value="PayData" v-if="PayData" :expand-depth='5' copyable  > </json-viewer>
    <br>
    <h3>DebugInfo:</h3>
    <json-viewer :value="DebugInfo" v-if="DebugInfo" :expand-depth='5' copyable  > </json-viewer>


  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { CreatePayOrder2023, QueryPayOrderAPI, UpdatePayOrderStateAPI, PayPutAPI } from "@/api/pay";
  import UserControl_Pay_SuiXingFu from '@/components/views/modules/UserControl_Pay_SuiXingFu.vue';
  import UserControl_Pay_ShouBei from '@/components/views/modules/UserControl_Pay_ShouBei.vue';
  export default {
    props: {
      SceneType: {type: String,default: '',},// 储值充值/堂食结账/快餐结账/外卖结账
      PayAmount:{type: Number,default:0,}, 
      BillID: {type: String,default: '',},
      CheckOrderCode: {type: String,default: '',},
      StoredRecordCode: {type: String,default: '',},
      GoodsOrderCode: {type: String,default: '',},
      KCOrderCode: {type: String,default: '',},
      PaySwitch: {type: Boolean,default: false,},
    },
    data() {
      return {
        PayOrderCode:'',
        PayService:'',
        PayData:null,
        ListenSwitch:true,

        DebugMsg:""
      }
    },
    computed: {
      ...mapGetters(['Store', 'DeskID', 'Member','Business','BusinessConfig','Business','OpenService' ]),
      PayOrder() {
        return {
          PayOrderCode:this.PayOrderCode,
          BusinessCode:this.Member.BusinessCode,
          StoreCode:this.Store.StoreCode,
          MemberCode:this.Member.MemberCode,
          SceneType:this.SceneType,
          PayAmount:this.PayAmount,
          BillID:this.BillID,
          CheckOrderCode:this.CheckOrderCode,
          StoredRecordCode:this.StoredRecordCode,
          GoodsOrderCode:this.GoodsOrderCode,
          KCOrderCode:this.KCOrderCode,
          PayCallBackOpenIDUrl:window.location.origin+'/pay/main',
          // PayCallBackUrl:this.OpenService+'Pay/PayOrder/CallBack'
          PayCallBackUrl:this.OpenService+'PayOrder/CallBack',
        };
      },
      DebugInfo(){
        return{
          ListenSwitch:this.ListenSwitch,
          DebugMsg:this.DebugMsg,
        }
      },
    },
    beforeDestroy(){
      this.ListenSwitch=false;
    },
    methods: {
      ...mapActions(['NeedMember']),
      // 组装支付组件，调试收呗支付服务,下放有随行付通道调试代码
      RunPay() {
        if(!this.SceneType){ return this.BackError('SceneType未配置！'); }
        if(!this.PayAmount){ return this.BackError('支付金额不可为零'); }
        if(!this.CheckOrderCode&&!this.StoredRecordCode&&!this.GoodsOrderCode&&!this.KCOrderCode){ return this.BackError('DataCode未配置'); }
        CreatePayOrder2023(this.PayOrder).then(res=>{
          if(res.state!=200){ return this.BackError('支付服务请求异常！'+res.msg); }
          this.PayOrderCode=res.data.PayOrderCode;
          this.PayService=res.data.PayService;
          this.PayData=res.data.PayData;
          this.OnActivePay();
          setTimeout(()=>{this.ListenPayState();},3000);
        }).catch(err=>{ return this.BackError('支付网络异常！'+ err); })

      },
      OnActivePay(){
        try {
          if (typeof WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', this.OnBridgeReady, false);
            }else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', this.OnBridgeReady); 
                document.attachEvent('onWeixinJSBridgeReady', this.OnBridgeReady);
            }
          }else{
            this.OnBridgeReady();
          }
        } catch (error) {
          this.DebugMsg=error;
          console.error('OnActivePay',error)
        }
      },
      OnBridgeReady(){
        var that=this;
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
            "appId":this.PayData.payAppId,     //公众号ID，由商户传入     
            "timeStamp":this.PayData.payTimeStamp,         //时间戳，自1970年以来的秒数     
            "nonceStr":this.PayData.paynonceStr, //随机串     
            "package":this.PayData.payPackage,     
            "signType":this.PayData.paySignType,         //微信签名方式：     
            "paySign":this.PayData.paySign, //微信签名 
          },
          function(res){
            console.log('getBrandWCPayRequest',res);
            that.DebugMsg=res.err_msg;
            if(res.err_msg == "get_brand_wcpay_request:ok" ){
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              this.PaySuccess(); 
              that.$emit("PayBack",{State:true,Msg:'支付成功',PayOrderCode:that.PayOrderCode})
            }
            else{
              this.PayNotPay(); 
              // 用户遇到错误或者主动放弃
              that.$emit("PayBack",{State:false,Msg:'支付用户遇到错误或者主动放弃',PayOrderCode:that.PayOrderCode})
            }
          }
        ); 
      },
      ListenPayState(){
        if(this.ListenSwitch){
          QueryPayOrderAPI({PayOrderCode:this.PayOrderCode}).then(res=>{
            if(res.state!=200){console.error('QueryPayOrderAPI.res.error',res)}
            else{
              var POrder=res.data;
              // 支付状态，1：成功，0：未支付，-1：支付失败
              if(!POrder){console.error('QueryPayOrderAPI.res.IsNull',res);this.ListenSwitch=false}
              else if(POrder.PayState==1){ this.ListenSwitch=false; this.PaySuccess(); }
              else if(POrder.PayState==-1){ this.ListenSwitch=false; this.PayNotPay(); }
              else{
                // 未支付继续侦听
              }
            }
          }).catch(err=>{console.debug('QueryPayOrderAPI.err',err)}).finally(res=>{
            setTimeout(()=>{this.ListenPayState();},1000);
          });
        }
      },
      PaySuccess(){
        console.log('感知到已支付成功！');
        this.DebugMsg="感知到已支付成功！";
      },
      PayNotPay(){
        console.log('感知用户未支付或已取消支付！');
        this.DebugMsg="感知用户未支付或已取消支付！";
      },
      BackError(Msg){
        console.error('BackError',Msg);
        this.DebugMsg=Msg;
        this.$emit("PayBack",{State:false,Msg:Msg})
      },
    },
    watch: {
      PaySwitch(newValue, oldValue) {
        if(!oldValue&&newValue){
          this.RunPay();
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>