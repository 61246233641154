<template>
  <div>

  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { CreatePayOrder2023, GetPayOrderAPI, UpdatePayOrderStateAPI, PayPutAPI } from "@/api/pay";
  export default {
    props: {
      SceneType: {type: String,default: '',},// 储值充值/堂食结账/快餐结账/外卖结账
      Amount:{type: Number,default:0,}, 
      BillID: {type: String,default: '',},
      CheckOrderCode: {type: String,default: '',},
      StoredRecordCode: {type: String,default: '',},
      GoodsOrderCode: {type: String,default: '',},
      KCOrderCode: {type: String,default: '',},
      PaySwitch: {type: Boolean,default: false,},
    },
    data() {
      return {
        PayOrder:null,

      }
    },
    computed: {
      ...mapGetters(['Store', 'DeskID', 'Member','Business','BusinessConfig','Business' ]),
      name() {
        return this.data 
      }
    },
    methods: {
      ...mapActions(['NeedMember']),
      RunPay() {
        
      }
    },
    watch: {
      PaySwitch(newValue, oldValue) {
        
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>