<template>
  <div id="app">
    <!-- <br/> loadState:{{loadState}}
    <br/> member:{{this.member?true:false}}
    <br/> activeBusiness:{{this.activeBusiness ?true:false}}
    <br/> activeBusinessConfig:{{this.activeBusinessConfig?true:false}}
    <br/> activeStoreCode:{{(!this.activeStoreCode && !this.routeQuery.StoreCode) || this.activeStore && this.activeStoreConfig?true:false}}
    <br/> activeDeskID:{{
                    (!this.activeDeskID && !this.routeQuery.DeskID)
                    && (!this.activeDeskCode && !this.routeQuery.DeskCode)
                    && (!this.activeDeskQrCode && !this.routeQuery.DeskQrCode)
                    || this.activeDesk
                    ?true:false}} -->
    <router-view v-if="!onLoading" @click="TakeCopy" />
    <template v-else>
      <div style="text-align: center; padding: 30%">
        <div class="progress-9"></div>
        加载中...
      </div>
    </template>
    <!-- <div>
        <div>IsDebug = {{IsDebug}}</div>
        <div>loadState = {{loadState}}</div>
        <div>onLoading = {{onLoading}}</div>
        <div>content = {{content}}</div>
        <div>memberCode = {{memberCode}}</div>
        <div>member = {{member?.NickName}}</div>
        <div>activeBusiness = {{activeBusiness?.BusinessName}}</div>
        <div>activeStoreCode = {{activeStoreCode}}</div>
        <div>activeStore = {{activeStore?.StoreName}}</div>
        <div>activeDeskID = {{activeDeskID}}</div>
        <div>activeDeskCode = {{activeDeskCode}}</div>
        <div>activeDesk = {{activeDesk?.DeskName}}</div>
      </div> -->
  </div>
</template>

<script>
import Vue from "vue";
import wx from "weixin-js-sdk";
import activeOrg from "@/store/sitedata/activeOrg";
import SubscribePage from "@/components/views/modules/SubscribePage.vue";
import { AppDeviceEnquire } from "@/utils/mixin";
import { mapActions, mapGetters } from "vuex";
import NutUI from "@nutui/nutui";
import { Toast, Indicator } from "mint-ui";
import moment from "moment";

import "@nutui/nutui/dist/nutui.scss";

NutUI.install(Vue);
export default {
  name: "App",
  mixins: [activeOrg, AppDeviceEnquire],
  components: {
    SubscribePage,
  },
  data() {
    return {
      CopyState: 0, //复制状态

      toast: null, // 加载组件

      content: "",
      onLoading: true,

      gap_time: 0,
      beforeUnload_time: 0,
    };
  },
  computed: {
    // ...mapGetters(["MemberCode", "StoreCode", "DeskID", "Member", "BusinessConfig"]),
    // 关注状态
    IsSubscribe() {
      // if (this.Member) { return this.Member.subscribe; }
      if (this.member) {
        return this.member.subscribe;
      } else {
        return 1;
      }
    },
  },
  created() {
    console.log("App.created", this.memberCode, this.loadState);
    if (!this.loadState) {
      Indicator.open("加载中...");
    }

    console.log("App.created.Over", this.$route);
  },
  mounted() {
    // if (!this.loadState) {
    //   Indicator.open("加载中...");
    // }

    console.log("96");
    document.addEventListener("WeixinJSBridgeReady", function onBridgeReady() {
      WeixinJSBridge.call("hideOptionMenu");
    });
    // wx.onMenuShareAppMessage({
    //   title: '感恩储值', // 分享标题
    //   desc: '大圣烧烤感恩储值', // 分享描述
    //   link: 'http://mobile.dsskvip.com/member', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //   imgUrl: '', // 分享图标
    //   type: '', // 分享类型,music、video或link，不填默认为link
    //   dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
    //   success: function () {
    //     // 用户点击了分享后执行的回调函数
    //     console.log('成功107')
    //     alert('108分享成功')
    //   }
    // });
    console.log("111");

    console.log("App.mounted", this.$route);
    if (this.$route.path != "/") {
      this.onLoadData(this.$route, 96);
    }

    // 监听浏览器关闭
    window.addEventListener("beforeunload", () => this.beforeunloadHandler());
    window.addEventListener("unload", () => this.unloadHandler());
  },
  destroyed() {
    // 移除监听
    window.removeEventListener("beforeunload", () =>
      this.beforeunloadHandler()
    );
    window.removeEventListener("unload", () => this.unloadHandler());
  },
  methods: {
    TakeCopy() {
      if (this.CopyState == 0 && this.BusinessConfig.AdsenseConfig?.CopyInfo) {
        var copy = document.getElementById("copyInfo");
        if (copy) {
          copy.style.display = "block";
          copy.select(); // 选择对象
          document.execCommand("Copy");
          copy.style.display = "none";
          this.CopyState = 1;
        }
      }
    },
    ...mapActions([
      "PushErrorPage",
      "NeedMember",
      "NeedBusiness",
      "NeedStore",
      "NeedBusinessConfig",
      "NeedDesk",
    ]),

    onLoadData(to, joinline) {
      console.log("onLoadData", joinline);
      if (this.$route.query.MemberCode && false) {
        console.log("121", to, this.$route.query.MemberCode, this.memberCode);
        if (
          this.$route.query.MemberCode != this.memberCode ||
          this.$route.query.DeskID != this.activeDeskID ||
          this.$route.query.DeskCode != this.activeDeskCode ||
          this.$route.query.StoreCode != this.activeStoreCode
        ) {
          this.initData();
          this.memberCode = this.$route.query.MemberCode;
        }
        var query = JSON.parse(JSON.stringify(to.query));
        query.Ver = moment().format("MMDDHHmmSS");
        Vue.delete(query, "MemberCode");
        this.$router.replace({ query: query });
        return;
      } else {
        console.log("133", to);
        this.onLoading = true;
        this.loadData(to.query)
          .then((res) => {
            console.log("loadData.then", res);
            if (res && Indicator) {
              Indicator.close();
            }
          })
          .finally((res) => {
            console.log(160);
            this.onLoading = false;
          });
      }
    },

    // 关闭窗口之前执行
    beforeunloadHandler() {
      this.beforeUnload_time = new Date().getTime();
    },
    unloadHandler() {
      this.gap_time = new Date().getTime() - this.beforeUnload_time;
      //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
      if (this.gap_time <= 5) {
        this.initData();
      } else {
      }
    },
  },
  watch: {
    loadState: {
      immediate: true,
      handler(newvalue) {
        console.log("loadState", newvalue);
        if (newvalue) {
          console.log("this.member", this.member, this.member?.MemberCode);
          console.log(
            "this.activeBusiness",
            this.activeBusiness,
            this.activeBusiness?.BusinessCode,
            this.activeBusinessCode
          );
          console.log("this.activeBusinessConfig", this.activeBusinessConfig);
          console.log(
            "this.activeStore",
            this.activeStore,
            this.activeStore?.StoreCode,
            this.activeStore?.StoreName,
            this.activeStoreCode
          );
          console.log("this.activeStoreConfig", this.activeStoreConfig);
          console.log(
            "this.activeDesk",
            this.activeDeskID,
            this.activeDesk?.DeskID,
            this.activeDeskCode,
            this.activeDesk?.DeskName,
            this.activeDesk
          );
        }
        if (newvalue) {
          Indicator.close();
        }
      },
    },
    $route(to, from) {
      console.log("route.change", to, this.$route.query.MemberCode);

      if (this.$route.query.MemberCode) {
        console.log("205", to);
        this.onLoadData(to, 206);
      } else {
        console.log("210", to);
        this.onLoadData(to, 211);
        // if(this.loadState){
        //   console.log(213)
        //   this.onLoading=false;
        // }
      }
    },
  },
};
</script>

<style>
/* #app {
  font-size: 14px;
} */

body {
  background-color: #f5f7f4;
}

img {
  position: relative;
}

img:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "无";
  display: block;
  top: 0;
  left: 0;
  background-image: none;
  background-color: gray;
  text-align: center;
  padding-top: 35%;
}

.mint-toast.toastWarn {
  border: 1px solid #ea454c;
  background-color: #ea454c;
  color: white;
}

.mint-toast.toastWarn .mint-toast-text {
  font-weight: bold;
}

.progress-9 {
  margin: 0 auto 20px;
  --r1: 154%;
  --r2: 68.5%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: radial-gradient(
        var(--r1) var(--r2) at top,
        #0000 79.5%,
        #ea454c 80%
      )
      center left,
    radial-gradient(var(--r1) var(--r2) at bottom, #ea454c 79.5%, #0000 80%)
      center center,
    radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #ea454c 80%) center
      right,
    #ccc;
  background-size: 50.5% 220%;
  background-position: -100% 0%, 0% 0%, 100% 0%;
  background-repeat: no-repeat;
  animation: p9 2s infinite linear;
}

@keyframes p9 {
  33% {
    background-position: 0% 33%, 100% 33%, 200% 33%;
  }

  66% {
    background-position: -100% 66%, 0% 66%, 100% 66%;
  }

  100% {
    background-position: 0% 100%, 100% 100%, 200% 100%;
  }
}
</style>
