<template>
  <div class="user-frozen-container">
    <img
      src="../../../../../static/images/timeout.png"
      alt="User Frozen"
      class="frozen-image"
    />
    <div class="content">
      <div>很抱歉，您的账号存在异常</div>
      <div>请联系服务员</div>
      <div style="margin-top: 30px">--会员信息--</div>
      <div>会员号：{{ MemberNumber }}</div>
      <div>昵称：{{ NickName }}</div>
      <div class="line"></div>
      <div
        v-if="BusinessConfig?.AdsenseConfig?.StoredDescription"
        v-html="BusinessConfig?.AdsenseConfig?.StoredDescription"
      ></div>
    </div>
  </div>
</template>
    
    <script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FrozenPage",

  data() {
    return {
      MemberNumber: "",
      NickName: "",
    };
  },
  computed: {
    ...mapGetters(["Member", "BusinessConfig", "Branch"]),
  },
  created() {
    if(this.$route.query){
      this.MemberNumber= this.$route.query.MemberNumber
      this.NickName= this.$route.query.NickName
    }
    else{
      this.MemberNumber=this.Member.MemberNumber
      this.NickName=this.Member.NickName
    }
  },
  
};
</script>
    
    <style scoped>
.user-frozen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
}

.frozen-image {
  width: 60%;
  margin-bottom: 20px;
}

.content {
  font-size: 18px;
}
</style>
<style>
.line {
  font-size: 18px;
  min-height: 30px;
  line-height: 30px;
  display: block;
}
</style>