import Vue from 'vue';
import moment from 'moment'


const getters = {
    MemberCode: state => { return Vue.ls.get('MemberCode'); },
    BusinessCode: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_BusinessCode'); },
    StoreCode: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_StoreCode'); },
    DeskID: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_DeskID'); },
    DeskCode: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_DeskCode'); },
    DeskQrCode: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_DeskQrCode'); },
    Person: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_Person'); },

    Member: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_Member'); },
    Business: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_Business'); },
    BusinessConfig: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_BusinessConfig'); },
    Store: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_Store'); },
    StoreConfig: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_StoreConfig'); },
    Desk: state => { return Vue.ls.get(Vue.ls.get('MemberCode') + '_Desk'); },


    // Member: state => { return state.active.member == null ? null : state.active.member },
    // Business: state => state.active.business,
    // BusinessConfig: state => state.active.businessconfig,
    // Store: state => state.active.store,
    // StoreConfig: state => state.active.StoreConfig,
    // Desk: state => state.active.desk,

    storedInfo: state => { return state.active.storedInfo == null ? null : state.active.storedInfo },
    Bill: state => state.active.bill,
    Editingorder: state => state.active.editingorder,
    MenuGroups: state => state.active.menuGroups,


    OpenService: state => state.active.OpenService,
    MediaService: state => state.active.MediaService,
    WeiXinService: state => state.active.WeiXinService,
    EmployeeSite: state => state.active.EmployeeSite,
    PlatformService: state => state.active.PlatformService,
    Branch: state => state.active.Branch,
    ActivityStartTime: state => state.active.ActivityStartTime,
    ActivityEndTime: state => state.active.ActivityEndTime,

    // OnActivity: state=>moment()>moment(state.active.ActivityStartTime)&& moment(new Date()) < moment(state.active.ActivityEndTime) && state.active.member?.BusinessCode == 'c5e6baa9861e452dbd420bc16721e474'
}

export default getters