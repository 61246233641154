import Vue from 'vue'
import { GetBusiness, GetBusinessConfig } from '@/api/business'
import { GetStore,GetStoreConfig } from '@/api/store'
import { GetMember } from '@/api/member'
import { GetDesk } from '@/api/baseinfo'
import DSSKData from './DSSKData.json'
import store from '@/store'
import moment from 'moment'

let Domain = document.domain === 'localhost' ? "jinshi.yubustep.cn" : (document.domain.indexOf('jinshi.yubustep.cn')>0? document.domain.split('.').slice(-3).join('.'):document.domain.split('.').slice(-2).join('.'));


const active = {
    state: {
        member: null,
        storedInfo:null,
        business: null,
        businessconfig: null,
        store: null,
        StoreConfig:null,
        desk: null,

        bill: null,
        editingorder: null,
        menuGroups: null,
        // MediaService: "http://www.dsskvip.com/api/",
        // WeiXinService: "http://weixin.dsskvip.com",

        OpenService: window.location.protocol + "//" + window.location.host + '/api/',//'http://open.yubustep.cn/'//window.location.protocol+"//"+window.location.host+'/api/'
        MediaService: document.domain.indexOf('localhost') >= 0 ? "http://open.dsskvip.com/" : "http://open." + Domain+'/',//"http://open.yubustep.cn/",
        WeiXinService: document.domain.indexOf('localhost') >= 0 ? "http://weixin.dsskvip.com/" : "http://weixin." + Domain+'/',
        PlatformService: document.domain.indexOf('localhost') >= 0 ? "http://platform.weixin.dsskvip.com/" : "http://platform.weixin." + Domain+'/',
        EmployeeSite: document.domain.indexOf('localhost') >= 0 ? "http://employeecenter.dsskvip.com/" : "http://employeecenter." + Domain+'/',
        Branch: document.domain.indexOf('localhost') >= 0 ? "Base" : (document.domain.indexOf('dsskvip') >= 0 ? "DSSK" :( (document.domain.indexOf('dsskvip.com') >= 0 ? "JinShi" :"Base"))),// 分支,Base,DSSK
    



        OpenService: document.domain.indexOf('localhost')>=0||document.domain.indexOf('192.168')>=0?"http://open.yubustep.cn/":window.location.protocol+"//"+window.location.host+'/',//'http://open.yubustep.cn/'//window.location.protocol+"//"+window.location.host+'/api/'
        // OpenService: 'http://fengchen180.imwork.net:35646/',
        MediaService: document.domain.indexOf('localhost')>=0||document.domain.indexOf('192.168')>=0?"http://open.yubustep.cn/":"http://open."+Domain+'/',//"http://open.yubustep.cn/",
        WeiXinService: document.domain.indexOf('localhost')>=0||document.domain.indexOf('192.168')>=0?"http://weixin.yubustep.cn/": "http://weixin."+Domain,//"http://weixin.yubustep.cn"
        Branch:document.domain.indexOf('localhost')>=0||document.domain.indexOf('192.168')>=0?"DSSK":document.domain.indexOf('dsskvip.com')>=0?"DSSK":document.domain.indexOf('192.168.31.240')>=0?"DSSK":"Base",// 分支,Base,DSSK
       
        
        // ActivityStartTime: '2023-09-01 00:00',
        // ActivityEndTime: '2023-10-01 18:00',

    },

    mutations: { 
        set_Desk: (state, value) => { state.desk = value },
        set_Member: (state, value) => { state.member = value },
        set_storedInfo: (state, value) => {state.storedInfo = value },
        set_Business: (state, value) => { state.business = value },
        set_BusinessConfig: (state, value) => { state.businessconfig = value },
        set_Store: (state, value) => { state.store = value },
        set_StoreConfig: (state, value) => { state.StoreConfig = value },
        set_Bill: (state, value) => { state.bill = value },
        set_EditingOrder: (state, value) => { state.editingorder = value; },
        set_MenuGroups: (state, value) => { state.menuGroups = value; },
    },
    actions: {     
        PushErrorPage({ commit }, me, res, msg = '') {
        },
        // 需要加载会员对象，页面Created时执行，放在头部，在then中执行后续内容，确保对象获取完成
        NeedMember({  commit }, me) {
          return new Promise((resolve, reject) => { 
            if (!store.getters.Member||store.getters.Member.MemberCode!=store.getters.MemberCode) { 
              if(!store.getters.MemberCode){
                reject({state:505,msg:"页面访问超时，请退出重新进入"});
              }
              else{ 
                GetMember({MemberCodeOrOpenID : store.getters.MemberCode})
                .then(res => {
                  if (res.state == 200) {
                      Vue.ls.set('BusinessCode', res.data.BusinessCode, 60 * 60 * 1000);
                      commit('set_Member', res.data);
                      resolve(res);
                  } else {
                      console.error("NeedMember.error", res); 
                      reject(res);
                  }
                }).catch(error => {
                    console.error("NeedMember.catch", error); 
                    reject(error);
                });
              }
            } 
            else { resolve({ state: 200, msg: '会员已加载',data:store.getters.Member }) }
          }) 
        },
        RefreshMember({ commit }, me){
          return new Promise((resolve, reject) => { 
              if(!store.getters.MemberCode){
                reject({state:505,msg:"页面访问超时，请退出重新进入"});
              }
              else{ 
                GetMember({MemberCodeOrOpenID : store.getters.MemberCode})
                .then(res => {
                  if (res.state == 200) {
                      Vue.ls.set('BusinessCode', res.data.BusinessCode, 60 * 60 * 1000);
                      commit('set_Member', res.data);
                      resolve(res);
                  } else {
                      console.error("NeedMember.error", res); 
                      reject(res);
                  }
                }).catch(error => {
                    console.error("NeedMember.catch", error); 
                    reject(error);
                });
              }
          }) 
        },
        // 需要加载商家对象，页面Created时执行，放在头部，在then中执行后续内容，确保对象获取完成
        NeedBusiness({  commit }, me) {
            return new Promise((resolve, reject) => {
              // console.log('NeedBusiness',new Date().getTime(),store.getters.ActivityStartTime)
              if(store.getters.OnActivity && store.getters.Branch=='DSSK' && store.getters.BusinessCode =='c5e6baa9861e452dbd420bc16721e474'){
                commit('set_Business', DSSKData.Business);
                resolve({state:200,data:DSSKData.Business});
              }
              else if (!store.getters.Business) {
                GetBusiness({BusinessCode:me?me:store.getters.BusinessCode}).then(res => {
                    // console.log('GetBusiness',JSON.stringify(res.data));
                    if (res.state == 200) { 
                      commit('set_Business', res.data);
                      resolve(res)
                  } else { 
                      reject(res);
                  }
                }).catch(error => {  
                    reject(error)
                })
              } 
              else { resolve({ state: 200, msg: '商家已加载',data:store.getters.Business }) }
            })
        },
        // 需要加载商家配置对象，页面Created时执行，放在头部，在then中执行后续内容，确保对象获取完成
        NeedBusinessConfig({  commit }, me) {
            return new Promise((resolve, reject) => {
              if(store.getters.OnActivity && store.getters.Branch=='DSSK'&& store.getters.BusinessCode =='c5e6baa9861e452dbd420bc16721e474'){
                commit('set_BusinessConfig', DSSKData.BusinessConfig);
                resolve({state:200,data:DSSKData.BusinessConfig});
              }
              else if (!store.getters.BusinessConfig||store.getters.BusinessConfig.BusinessCode!=!store.getters.BusinessCode) { 
                    GetBusinessConfig({BusinessCode:me?me.me.BusinessCode:store.getters.BusinessCode})
                    .then(res => {
                      // console.log('GetBusinessConfig',JSON.stringify(res.data));
                        if (res.state == 200) {
                          res.data.MpMenuConfig=JSON.parse(res.data.MpMenuConfigJson);
                          res.data.TemplateMsgConfig=JSON.parse(res.data.TemplateMsgConfigJson); 
                          res.data.AdsenseConfig=JSON.parse(res.data.AdsenseConfigJson);
                          res.data.MemberConfig=JSON.parse(res.data.MemberConfigJson);
                          commit('set_BusinessConfig', res.data);
                          resolve(res);
                        } else { 
                            reject(res);
                        }
                    })
                    .catch(error => { 
                        reject(error)
                    })
                } 
                else {
                  resolve({ state: 200,data:store.getters.BusinessConfig, msg: '商家配置已加载' }) 
                }
            })
        },
        // 需要加载门店对象，页面Created时执行，放在头部，在then中执行后续内容，确保对象获取完成
        NeedStore({  commit }, me) {
            return new Promise((resolve, reject) => {
              // var StoreCode=Vue.ls.get("StoreCode");
              var StoreCode=store.getters.StoreCode;
                if (!store.getters.Store||store.getters.Store.StoreCode!=StoreCode) { 
                  GetStore({StoreCode:StoreCode}).then(res => {
                    if (res.state == 200) {
                        commit('set_Store', res.data);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                  }).catch(error => {
                      reject(error)
                  })
                } 
                else { resolve({ state: 200, msg: '门店已加载',data:store.getters.Store }) }
            })
        },
        NeedStoreConfig({  commit }, me) {
            return new Promise((resolve, reject) => {
              // var StoreCode=Vue.ls.get("StoreCode");
              var StoreCode=store.getters.StoreCode;
              if (!store.getters.StoreConfig||store.getters.Store.StoreCode!=StoreCode) { 
                  GetStoreConfig({StoreCode:StoreCode}).then(res => {
                    if (res.state == 200) {
                        commit('set_StoreConfig', res.data);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                  }).catch(error => {
                      reject(error)
                  })
                } 
                else { resolve({ state: 200, msg: '门店已加载',data:store.getters.Store }) }
            })
        },
        // 需要加载台位对象，页面Created时执行，放在头部，在then中执行后续内容，确保对象获取完成
        NeedDesk({  commit }, me) {
            return new Promise((resolve, reject) => {
              if (!store.getters.Desk||store.getters.Desk.DeskID!=store.getters.DeskID) { 
                  GetDesk({DeskID : store.getters.DeskID,StoreCode:store.getters.StoreCode,DeskCode:me.DeskCode}).then(res => {
                    if (res.state == 200) {
                      if(res.data && res.data.State==1){
                        commit('set_Desk', res.data);
                      }
                        resolve(res);
                    } else {
                        reject(res);
                    }
                  }).catch(error => {
                      reject(error);
                  });
                } 
                else { resolve({ state: 200, msg: '台位已加载',data:store.getters.Desk }) }
            })
        },
    }
}

export default active