<template>
  <div class="bodybox">
    <!-- <div class="title">{{ MemberName }}，欢迎光临！</div> -->
    <div class="pic"></div>
    <div class="msg">台位信息已失效</div>
    <div class="describe">您需要重新扫码台位二维码</div>
    <!-- <div class="closebtn" @click="ExitPage">关闭</div> -->
    <router-link :to="{ name: 'DishMenu' }">随便看看</router-link>
  </div>
</template>

<script>
import Vue from 'vue'
import activeOrg from '@/store/sitedata/activeOrg'
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [activeOrg],
  data() {
    return {
      StoreCode: ''
    }
  },
  computed: {
    MemberName() {
      return this.member.MemberName ? this.member.MemberName : this.member.NickName
    },
  },
  created() {
    //   console.log('this.$route', this.$route);
  },
  mounted() {

    Vue.ls.remove(this.member.MemberCode+'_DeskID');
    this.$store.commit('set_Desk', null);
    //防止页面后退
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL);
    });
  },
  methods: {
    ExitPage() {
      WeixinJSBridge.call('closeWindow');
    },
  },
}
</script>

<style scoped>
body {
  background-color: #f7f7f7;
}

.bodybox {
  width: 350px;
  text-align: center;
  position: absolute;
  top: 60px;
  bottom: 20px;
  left: 50%;
  margin-left: -175px;
}

.title {
  font-size: 22px;
  font-weight: bold;
  line-height: 50px;
}

.msg {
  font-size: 18px;
  line-height: 30px;
  ;
}

.describe {
  font-size: 14px;
  color: #707070;
  line-height: 30px;
  ;
}

.pic {
  background: url('../../../../static/images/timeout.png') no-repeat center;
  width: 350px;
  height: 150px;
  background-size: 50%;
}

.tomore {
  font-size: 14px;
  color: #707070;
}

.closebtn {
  background: #eb5429;
  width: 260px;
  line-height: 40px;
  margin: 30px auto;
  text-align: center;
  font-size: 14px;
  color: white;
  border-radius: 20px;
}

.order-list-box .title-box {
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  color: #ffbb05;
  border-bottom: 1px solid #e6e6ea;
}

.order-list-box .product-list {
  padding: 0 20px;
  ;
}

.order-list-box .product-list li {
  clear: both;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.order-list-box .product-list li .rownumber {
  width: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: left;
  float: left;
}

.order-list-box .product-list li .dishname {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: left;
  float: left;
}

.order-list-box .product-list li .price {
  color: initial;
  font-size: 14px;
}

.order-list-box .product-list li .number {
  float: right;
  font-size: 14px;
}
</style>