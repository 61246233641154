<template>
  <div>
    <UserControlPay SceneType="储值充值" :PayAmount="0.01" :StoredRecordCode="StoredRecordCode" :PaySwitch="PaySwitch" @PayBack="PayBack"></UserControlPay>

    <div style="text-align:center">
      <br>
      <mt-button type="primary" @click="PaySwitch=true">去支付</mt-button>
      <br><br><br><br>
      <a href="http://localhost:8080/PayControlTest?MemberCode=836bdfae69ab4d94a4cbffb091e1b9d2&StoreCode=9c38a659959a45a2ae939c25eecdc731&DeskID=02e18a3db8494c9394835aecb08548d0&Ver=2329062300">
        <mt-button type="danger" >生产环境 - 都师傅烩面 - 冯陈</mt-button>
      </a>
    </div>
  </div>
</template>

<script>
  import UserControlPay from '@/components/views/modules/UserControl_Pay.vue';
  export default {
    components: {UserControlPay,},
    data() {
      return {
        PaySwitch: false,
        StoredRecordCode:'123',
      }
    },
    methods: {
      PayBack(res) {
        console.log('PayBack',res);
        this.PaySwitch=false;
      }
    },

  }
</script>

<style lang="scss" scoped>

</style>