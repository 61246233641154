import { axios } from '@/utils/request'

export function CreatePayOrder(parameter) { return axios({ url: '/Pay/Create', method: 'post', data: parameter }); }
export function GetPayOrder(parameter) { return axios({ url: '/Pay/Get', method: 'post', data: parameter }); }
export function UpdatePayOrderState(parameter) { return axios({ url: '/Pay/UpdateState', method: 'post', data: parameter }); }
export function PayPut(parameter) { return axios({ url: '/Pay/PayPut', method: 'post', data: parameter }); }

export function CreatePayOrder2023(parameter) { return axios({ url: '/Pay/Create', method: 'post', data: parameter }); }
export function QueryPayOrderAPI(parameter) { return axios({ url: '/Pay/Query', method: 'post', data: parameter }); }
export function UpdatePayOrderStateAPI(parameter) { return axios({ url: '/Pay/UpdateState', method: 'post', data: parameter }); }
export function PayPutAPI(parameter) { return axios({ url: '/Pay/PayPut', method: 'post', data: parameter }); }


export function ShouBeiGetOpenIDUrl(parameter) { return axios({ url: '/Pay/ShouBei/GetOpenIDUrl', method: 'post', data: parameter }); }
